
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import { JFGrid } from "jfrog-ui-vue-essentials";
import { SubscriptionDTO } from "@jfrog-ba/myjfrog-common";
import { ViewContext } from "@/types/services/app";
import { PageTitleProps } from "@/types/layout/pageTitle";
import { FlatSubscription, JFGridSearch } from "@/types/localtypes";
import { MenuConfiguration } from "@/types/services/menus";
import PageSection from "@/components/layout/PageSection.vue";
import PageTitle from "@/components/layout/PageTitle.vue";
import PageView from "@/components/layout/PageView.vue";
import SubscriptionCard from "@/components/views/subscriptions/SubscriptionCard.vue";

@Component({
  name: "SubscriptionsList",
  components: { PageView, PageTitle, PageSection, JFGrid, SubscriptionCard },
})
export default class SubscriptionsList extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Action("setMenuConfiguration", { namespace: "application" })
  setMenuConfiguration!: (menuConfiguration: MenuConfiguration) => void;
  @Action("setViewContext", { namespace: "application" })
  setViewContext!: (viewContext: ViewContext) => void;

  @Watch("subscriptions")
  async onSubscriptionsChanged() {
    this.setMenuConfiguration(await this.defineMenuConfiguration());
    this.setViewContext(this.defineViewContext());
  }
  defineViewContext(): ViewContext {
    return this.subscriptions ? { subscriptionMetas: this.subscriptionsMetas } : {};
  }
  subscriptions: SubscriptionDTO[] = [];
  transformedSubscriptions: FlatSubscription[] = [];
  isLoading: boolean = true;
  gridProps: any = {
    theme: "light",
    displayToolbar: false,
    columns: [
      {
        label: "Subscription",
        field: "displayName",
        tooltip: false,
        sortable: true,
        flexGrow: 1,
        cellCssClasses: ["grid-clickable-span"],
        onClick: this.goToSubscription,
      },
      {
        label: "Included Services",
        field: "serverName", // not usefull
        tooltip: false,
        sortable: true,
        getHtml: (val: string, row: FlatSubscription) => {
          const includedServices = this.$jfSubscriptions.getIncludedServices(row.meta);
          return !!includedServices.length ? includedServices.join(", ") : "-";
        },
      },
      {
        label: "Server Name",
        field: "serverName",
        tooltip: false,
        sortable: true,
      },
      {
        label: "Cloud Provider",
        field: "cloudProvider",
        tooltip: false,
        sortable: true,
        flexGrow: 1,
        getHtml: (val: string, row: FlatSubscription) => {
          if (val) {
            const logoName: string = this.$jfImages.getProviderLogoFileName(row.cloudProviderCode);
            return `<div fx fxacenter fxnowrap  ><img width="22" src="${this.$jfImages.get(
              logoName,
            )}" mr-2/> <div class="cell-ellipsis">${val}</div></div>`;
          } else {
            return "-";
          }
        },
      },
      {
        label: "Region",
        field: "region",
        tooltip: false,
        sortable: true,
        converter: (val: string) => {
          return val;
        },
      },
      {
        label: "Status",
        field: "status",
        tooltip: false,
        sortable: false,
        getHtml: (val: string, subscription: FlatSubscription) => {
          const state = this.$utils.capitalize(val.toLowerCase());
          const isActive = val.toLowerCase() === "active";
          const iconName = isActive ? "icon-mjf-active" : "icon-mjf-canceled";
          const iconColor = isActive ? "mjf-green" : "mjf-red";
          const meta = subscription.meta;
          let stateSuffix = "";
          if (meta.isTrial && !meta.isExpiredTrial && isActive) {
            const daysLeft = this.$dateUtils.dayDiff(new Date(), subscription.endOfTrial);
            const metaText = daysLeft === 0 ? "Last Day" : `${daysLeft} day${daysLeft > 1 ? "s" : ""} remaining`;
            // const metaIconColorClass = daysLeft <= 5 ? "danger" : daysLeft <= 14 ? "warning" : "info";
            const metaIconColorClass = "info";
            stateSuffix = `<span ml-2><i class="icon-art-xray-moitoring-alert-new alert-trial-icon ${metaIconColorClass}"></i> ${metaText}</span>`;
          }
          return `<div fx fxacenter><i class="${iconName} ${iconColor}" mr-2 ></i> <span>${state}</span>${stateSuffix}</div>`;
        },
      },
    ],
    state: {
      page: 1,
      orderBy: "displayName",
      order: "desc",
    },
    config: {
      fetchDataPromise: this.fetchDataPromise,
      noPagination: true,
      mode: "client",
      onFilter: (subscription: FlatSubscription, search: JFGridSearch) => true,
    },
  };

  get nbSubscriptions(): number {
    return this.subscriptions.length;
  }
  get pageTitleProps(): PageTitleProps {
    return {
      title: `${this.isLoading ? "Loading..." : `${this.nbSubscriptions} Subscriptions Available`}`,
    };
  }
  fetchDataPromise(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        this.subscriptions = await this.$jfSubscriptions.getSubscriptions();
        this.transformedSubscriptions = this.$jfSubscriptions.transformSubscriptions(this.subscriptions);
        this.isLoading = false;
        resolve({ data: this.transformedSubscriptions, total: this.transformedSubscriptions.length });
      } catch (error) {
        this.isLoading = false;
        this.$log.error(error);
        this.$jfNotification.error({
          text: this.$jfMessages.subscriptions_list_fetch_failed,
        });
        reject(error);
      }
    });
  }

  goToSubscription(subscription: SubscriptionDTO): void {
    // @ts-ignore
    this.$router.push({
      name: "subscriptions.detail",
      params: { accountNumber: subscription.accountNumber.toString() },
    });
  }

  get gridIsDisplayed() {
    return this.$mq !== "mobile";
  }

  get subscriptionsMetas() {
    return this.subscriptions.map(s => s.meta);
  }

  async defineMenuConfiguration(): Promise<MenuConfiguration> {
    this.globalBus.$emit("onMenuConfigurationChange");
    if (this.subscriptions) {
      return await this.$jfMenus.defineNotContextualMenuConfiguration(
        this.subscriptions,
        (menuId, serverName, tooltipContent, tooltipHasSubmitAction, meta) => {
          this.globalBus.$emit(
            "onMenuTooltipClick",
            menuId,
            serverName,
            tooltipContent,
            tooltipHasSubmitAction,
            this.$jfSubscriptions.getSubscriptionRequestReason(menuId, meta),
          );
        },
      );
    }
    return {};
  }

  mounted() {
    this.$jfModal.closeAll();
    if (!this.gridIsDisplayed) {
      // we are in small screen so the jfGrid is not displayed. Then the fetchData method should be called manually
      this.fetchDataPromise();
    }
  }
}
