
import { FlatSubscription } from "@/types/localtypes";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "SubscriptionCard",
  components: {},
})
export default class SubscriptionCard extends Vue {
  @Prop({ default: undefined })
  private subscription!: FlatSubscription;

  get cardWrapperClasses() {
    let classes = [this.subscription.status.toLowerCase()];
    return classes;
  }

  get isActive() {
    return this.subscription.status === "Active";
  }
  get statusIcon() {
    return this.isActive ? "icon-mjf-active" : "icon-mjf-canceled";
  }
  get statusColor() {
    return this.isActive ? "mjf-green" : "mjf-red";
  }
  get statusIconClasses() {
    let classes: string[] = [];
    classes.push(this.statusIcon, this.statusColor);
    return classes;
  }

  get logoPath() {
    return this.$jfImages.get(this.$jfImages.getProviderLogoFileName(this.subscription.cloudProviderCode));
  }

  get includedServices() {
    return this.$jfSubscriptions.getIncludedServicesLabel(this.subscription.meta);
  }
}
